import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import './promoted.scss';

import PromotedItem from './promotedItem';
import { LoadingAdmin } from '@shared/ui';
import { Text, Button,  Icon } from '@ui-kit';
import {
    updateProductsSettingsTC,
    getReadyProductsTC,
    getCurrentProducts,
    setProductsAC,
    getPaginationAmount,
    getIsLoading,
    setSearchPageAC,
  } from '@entities';
import CheckIcon from "@ui-kit/icons/check-accent500-md.svg";

const PromotedProducts = () => {
  const dispatch = useDispatch();
  const products = useSelector(getCurrentProducts);
  const productsPaginationAmount = useSelector(getPaginationAmount);
  const productsIsLoading = useSelector(getIsLoading)

  const [checkedProducts, setCheckedProducts] = useState([]);
  const [forceRender, setForceRender] = useState(true);
  const [page, setPage] = useState();
  // eslint-disable-next-line
  const [field, setField] = useState();
  // eslint-disable-next-line
  const [order, setOrder] = useState();
  // eslint-disable-next-line
  const [allPage, setAllPage] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if(productsIsLoading) {
      return setForceRender(true);
    }

    setTimeout(() => setForceRender(false), 1000);
  }, [productsIsLoading]);

  useEffect(() => {
    dispatch(setSearchPageAC('promote'));
    dispatch(getReadyProductsTC({limit: 30, page: 0, isPromoted: 1}));

    return () => {
      dispatch(setProductsAC([]));
    };
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
      const params = getSearchParams();
  
      params.forEach(({ key, value }) => {
        switch (key) {
          case 'page': {
            if (!+value || +value !== 1) {
              searchParams.set('page', 1);
              setSearchParams(searchParams, {replace: true});
              return setPage(1);
            }
  
            setPage(value);
            break;
          }
  
          case 'limit': {
            if (!+value || +value !== 30) {
              searchParams.set('limit', 30);
              setSearchParams(searchParams, {replace: true});
              setAllPage(Math.ceil(+productsPaginationAmount));
  
              return
            }
  
            const allPages = Math.ceil(+productsPaginationAmount / +value);
            
            setAllPage(allPages);
  
            if (allPages < page) {
              setPage(allPages);
              searchParams.set('page', allPages);
              setSearchParams(searchParams, {replace: true});
            }
  
            break;
          }
  
          case 'fieldType': {
              // views, createdAt
            if (!value || value === '') {
              searchParams.set('fieldType', 'createdAt');
              setSearchParams(searchParams, {replace: true});
              setField('createdAt');
              return;
            }
  
            setField(value);
            break;
          }
  
          case 'orderType': {
              // DESC, ASC
            if (!value || value === '') {
              searchParams.set('orderType', 'DESC');
              setSearchParams(searchParams, {replace: true});
              setOrder('DESC');
              return;
            }
  
            setOrder(value);
            break;
          }
  
          default:
            break;
        }
      });
  
  
      return () => {
      };
  
      // eslint-disable-next-line
  }, [searchParams, productsPaginationAmount]);

  const onUpdateData = () => {
    const limit = searchParams.get('limit') || 30;
    const page = searchParams.get('page') || 0;
    const isPromoted = searchParams.get('isPromoted') || 1;
    
    return setTimeout(() => 
      dispatch(getReadyProductsTC({ limit: limit, page: page, isPromoted: isPromoted }))
  , 500);
  };
  
  const getSearchParams = () => {
    const params = [];

    searchParams.forEach((value, key) => {
      params.push({ key, value });
    });

    return params;
  };

  const onCheckedProducts = (checkedProducts) => {
    setCheckedProducts(checkedProducts);
  }

  const onDelete = () => {
    const prodIds = [];
    checkedProducts.map((item) => prodIds.push(item.id));

    dispatch(updateProductsSettingsTC({ ids: prodIds, update: {isPromoted: 0}}))

    onUpdateData();
  };

  const renderButton = () => {
    if (checkedProducts.length) {
        return `Selected to remove ${checkedProducts.length} out of ${products.length}`;
    }

    return `Select to remove`;
  }

  const renderPromote = () => {
    if (forceRender) {
      return <LoadingAdmin /> 
    }
    
    if(!products.length ) {
      return (
        <>
          <div className="promoted__header pr">
            <Text as="h2" className="title">Promote your products!</Text>
            <Text as="h5" className="subtitle">
              By featuring up to 30 selected illustrations you can boost 
              recognition and increase earnings. Simply select illustrations 
              that you want to display in the first rows of the gallery.
            </Text>
            <div className="banner">
              <Icon src="/images/banner-promote.png" />
            </div>
          </div>
          <div className="promote-empty__content">
            <div className="list-content d-flex">
              <Icon src={CheckIcon} />
              <Text as="h5">You can always promote up to 30 products</Text>
            </div>
            <div className="list-content d-flex">
              <Icon src={CheckIcon} />
              <Text as="h5">You can always replace illustrations</Text>
            </div>
            <div className="list-content d-flex">
              <Icon src={CheckIcon} />
              <Text as="h5">
                You can always cancel promotion
              </Text>
            </div>
          </div>
        </>
      )
    };

    return (
      <>
        <div className="promoted__header"> 
          <Text as="h4" className="title">
            Promoted products
          </Text>
          <Text as="h6" className="subtitle">
            The selected products displayed in the 
            first rows on the Gallery page. However, this will 
            not affect search results.
          </Text>
            <Button 
              theme="secondary-gray-danger"
              size="md"
              isDisabled={!checkedProducts.length}
              onClick={onDelete}
            >
              {renderButton()}
            </Button>
        </div>

        <div className="promoted__table-body">
          {products.map((item, index) => {
            return (
              <PromotedItem 
                  key={`product-${index}`}
                  item={item}
                  checkedItems={checkedProducts}
                  setCheckedItems={onCheckedProducts}
              />
            )
          })}
        </div>
      </>
    )
  }

  return (
      <div className="promoted__container">
        {renderPromote()}
      </div>
  )
};

export default PromotedProducts;