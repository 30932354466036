import React, { useCallback, useRef, useEffect } from 'react';
import './home_gallery.scss';
import Slider from 'react-slick';
import { useSelector, useDispatch } from 'react-redux';
import { Text, Icon, CarouselArrow } from '@ui-kit';
import { BROWSE_URL } from '@app/routes';
import { HoverProductCard } from '@widgets';
import {
  getCart,
  getAllAmount,
  addProductToCartTC,
  getProductsAmountTC,
  SET_ENTIRE_AMOUNT,
} from '@entities';

import { GALLERY_SLIDERS_QA } from './gallery.consts.qa';
import { GALLERY_SLIDERS_PROD } from './gallery.consts.prod';

const PRODUCT_URL = process.env.REACT_APP_PRODUCTS_URL;

const Gallery = () => {
  const dispatch = useDispatch();
  const sliderElementFirst = useRef(null);
  const sliderElementSecond = useRef(null);
  const totalAmount = useSelector(getAllAmount);
  const cart = useSelector(getCart);

  const mode = process.env.REACT_APP_MODE;
  console.log('[mode]-', mode);
  let GALLERY_SLIDERS = GALLERY_SLIDERS_QA;

  if (mode === 'prod') {
    GALLERY_SLIDERS = GALLERY_SLIDERS_PROD;
  }

  const { SLIDER_BOTTOM, SLIDER_TOP } = GALLERY_SLIDERS;

  useEffect(() => {
    dispatch(getProductsAmountTC(null, SET_ENTIRE_AMOUNT));
  }, [dispatch]);

  let settingsTop = {
    infinite: true,
    speed: 700,
    centerMode: true,
    variableWidth: true,
    arrows: false,
    slidesToScroll: -1,
  };

  const settingsBottom = {
    ...settingsTop,
  };

  const addToCart = useCallback((event, product) => {
    event.stopPropagation();
    event.preventDefault();

    dispatch(addProductToCartTC(product));
    // eslint-disable-next-line
  }, []);

  const defineItemInCart = (pathToBrowse) => {
    const product = cart.find((item) => item.pathToBrowse === pathToBrowse);

    if (product?.pathToBrowse) {
      return true;
    }

    return false;
  };

  const navigateTo = useCallback((slide) => {
    // const href = `${BROWSE_URL.pathname}/${slide.linkId}-${slide.id}`;
    const href = `${BROWSE_URL.pathname}/${slide.linkId}`;
    window.open(href, '_blank');
  }, []);

  const renderSlider = (sliderItems, settings, sliderElement) => {
    return (
      <Slider ref={sliderElement} {...settings}>
        {sliderItems.map((slide, index) => (
          <div
            key={Date.now() + index}
            className="slide__item pr background__static"
            onClick={() => navigateTo(slide)}
          >
            <Icon
              src={`${PRODUCT_URL}/${slide.pathToBrowse}`}
              alt={slide.alt}
            />
            {slide.user && (
              <HoverProductCard
                author={slide.user}
                inCart={defineItemInCart(slide.pathToBrowse)}
                addToCart={(e) => addToCart(e, slide)}
              />
            )}
          </div>
        ))}
      </Slider>
    );
  };

  const showNext = () => {
    sliderElementFirst?.current?.slickNext();
    sliderElementSecond?.current?.slickNext();
  };

  const showPrev = () => {
    sliderElementFirst?.current?.slickPrev();
    sliderElementSecond?.current?.slickPrev();
  };

  return (
    <div className="gallery__container">
      <div className="main__container gallery__content">
        <Text as="h1">Works of our illustrators</Text>
        <Text as="h3">
          Our gallery grows constantly. There are
          <Text as="span" className="gallery__count">
            {totalAmount}
          </Text>
          illustrations now,
          <Text as="span" className="gallery__count">
            0
          </Text>
          of them are free.
        </Text>
      </div>
      {renderSlider(SLIDER_TOP, settingsTop, sliderElementFirst)}
      {renderSlider(SLIDER_BOTTOM, settingsBottom, sliderElementSecond)}
      <div className="d-flex jc-center mt-24 gallary__arows">
        <CarouselArrow onClick={showPrev} size="lg" />
        <CarouselArrow onClick={showNext} size="lg" direction="next" className="ml-40" />
      </div>
    </div>
  );
};

export default Gallery;
