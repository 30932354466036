import React, { useEffect, useState, cloneElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import './userPanelWrapper.scss';

import {
  Asside,
  AdminContent,
  UserInfoMenu,
  BriefInfoComponent,
  UserProjectInfo,
  FaqInfoComponent,
  ModalActivatedEmail,
} from '@widgets';
import { 
  isAuth,
  getCustomer, 
  setIsMobileScreenViewAC, 
  getIsMobileScreenView,
  getActivatedEmailShow, 
} from '@entities';
import { ADMIN_LOGIN_URL } from '@app/routes';

const UserPanelWrapper = ({
  children = () => {},
  isDisabledCreateProject = false,
  className = '',
  projects,
  currentProject,
  setDisabledChat,
  isChatView,
  setIsChatView,
}) => {
  const MIN_WIDTH = 670;
  const MAX_WIDTH = 1028;
  const dispatch = useDispatch();
  
  const user = useSelector(getCustomer);
  const isUserAuth = useSelector(isAuth);
  const isMobileScreenView = useSelector(getIsMobileScreenView);
  const isActivatedEmailShow = useSelector(getActivatedEmailShow);

  const [activePanel, setActivePanel] = useState('faq');
  const [isSmallWidth, setIsSmallWidth] = useState(window.innerWidth <= MIN_WIDTH);
  const [isMaxWidth, setIsMaxWidth] = useState(window.innerWidth >= MAX_WIDTH);

  const [searchParams, setSearchParams] = useSearchParams();

  const adminStyles = ['admin', 'd-flex', ...className.split(' ')];
  const loginClass = !isUserAuth ? "login" : "";
  const userStyles = ["user-info__wrapper",  loginClass];
  const mobileStyles = ["mobile-user-panel__wrapper"];

  if(isChatView && isSmallWidth) {
    mobileStyles.push("chat-open");
  };

  useEffect(() => {
    const userInfoActive = getFieldQuertParam('user_info_active');
    if (userInfoActive && userInfoActive !== 'hidden') {
      setActivePanel(userInfoActive);
    } else {
      setActivePanelAndQuery('faq');
    };

    const handleResize = (event) => {
      const eventWidth = event.target.innerWidth;
      if (eventWidth <= MIN_WIDTH) {
        setIsSmallWidth(true);
      } else {
        setIsSmallWidth(false);
      }

      if (eventWidth >= MAX_WIDTH) {
        setIsMaxWidth(true);
      } else {
        setIsMaxWidth(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const project_asset_id = searchParams.get('project_asset_id');
    if(project_asset_id && isMobileScreenView) {
      setIsChatView(true)
    };
    // eslint-disable-next-line
  }, [searchParams]);

  useEffect(() => {
    const header = document.querySelector('header');
    const main = document.querySelector('.main__content');

    if(isChatView && isSmallWidth) {
      header.style.display = 'none';
      main.style.padding = '0';
    } else {
      header.style.display = '';
      main.style.padding = '';
    };
    // eslint-disable-next-line
  }, [isChatView, isSmallWidth]);

  function getFieldQuertParam(field) {
    const params = Object.fromEntries(searchParams.entries());

    return params[field];
  }

  const setActivePanelAndQuery = (panelName) => {
    setActivePanel(panelName);
    searchParams.set('user_info_active', panelName);
    setSearchParams(searchParams, {replace: true});
  };

  const activePanelsSet = {
    brief: <BriefInfoComponent />,
    info: <UserProjectInfo />,
    faq: <FaqInfoComponent />,
  };

  const setActiveInfo = (activePanel) => {
    let pcPanel = activePanel === 'hidden' ? 'faq' : activePanel;
    setActivePanel(pcPanel);

    searchParams.set('user_info_active', pcPanel);
    setSearchParams(searchParams, {replace: true});
  };

  const renderActiveInfoPanel = () => {
    return activePanelsSet[activePanel];
  };

  const renderUserPanel = () => {
    const location = window.location;
    const isLoginPage = location.pathname.includes(ADMIN_LOGIN_URL.pathname);
    const isConfirmPage = location.pathname.includes('account/password/confirm');
    const isRejectPage = location.pathname.includes('account/password/reject');

    if(isSmallWidth &&
      !isLoginPage &&
      !isConfirmPage &&
      !isRejectPage
    ) {
      dispatch(setIsMobileScreenViewAC(true));
      
      return (
        <div className={mobileStyles.join(" ")}>
          {!!isActivatedEmailShow && (
            <ModalActivatedEmail
              className="notification-popup__wrapper"
              customerEmail={user.email}
            />
          )}

          <Asside
            projects={projects}
            currentProject={currentProject}
            setActivePanelAndQuery={setActivePanelAndQuery}
            isDisabledCreateProject={isDisabledCreateProject}
            setDisabledChat={setDisabledChat}
            setIsChatView={setIsChatView}
          />
          <AdminContent>
            {cloneElement(children, { setUserActiveInfo: setActiveInfo })}
          </AdminContent>
        </div>
      )
    }

    dispatch(setIsMobileScreenViewAC(false));

    return (
      <>
        {!!isActivatedEmailShow && (
          <ModalActivatedEmail
            className="notification-popup__wrapper"
            customerEmail={user.email}
          />
        )}

        <Asside
          projects={projects}
          currentProject={currentProject}
          setActivePanelAndQuery={setActivePanelAndQuery}
          isDisabledCreateProject={isDisabledCreateProject}
          setDisabledChat={setDisabledChat}
          setIsChatView={setIsChatView}
        />

        <AdminContent>
          {cloneElement(children, { setUserActiveInfo: setActiveInfo })}
        </AdminContent>
        
        {isMaxWidth && (
          <div className={userStyles.join(" ")}>
            <UserInfoMenu setActive={setActiveInfo} isActive={activePanel} />
            <div className="panel-info__wrapper">{renderActiveInfoPanel()}</div>
          </div>
        )}
        
      </>
    )
  };

  return (
    <div className={adminStyles.join(' ')}>
      {renderUserPanel()}
    </div>
  );
};

export default UserPanelWrapper;
