import { useState } from 'react';

const useForm = (formState, callback) => {

  const [formData, setFormData] = useState({...formState, isUnsaved: false });
  const [lastEvent, setLastEvent] = useState({});
  const [stateForm, setStateForm] = useState({});

  const onChangeInput = (e) => {
    const { name, value } = e.target;

    console.log('onChangeInput', name, formData[ name ], value);

    const isUnsaved = formData[ name ] !== value;

    setFormData({ ...formData, isUnsaved, [name]: value });
    setLastEvent({[name]: value})
  };

  const onSubmitForm = (e) => {
    e.preventDefault();
    callback();
    formData.isUnsaved = false;
  };

  const watch = () => {
    if(!Object.keys(lastEvent).length) {
      return false;
    };

    const key = Object.keys(lastEvent)[0];
    const value = lastEvent[key];
    const oldKey = Object.keys(stateForm)[0];
    const oldValue = stateForm[oldKey];

    if(key === oldKey && oldValue === value) {
      return false;
    };

    setStateForm(lastEvent);

    return lastEvent;
  };

  return {
    watch,
    formData,
    onChangeInput,
    onSubmitForm,
    setFormData: newFormData => setFormData({

      ...newFormData,

      isUnsaved: JSON.stringify(formData) !== JSON.stringify(newFormData),

    }),
  };
};

export default useForm;
