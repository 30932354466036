import React, { useState, useEffect } from 'react';
import './productEditInfo.scss';

import productFormValid from './product-validation.hook';
import { useForm } from '@shared';
import { Icon, Text, Button, Input, TextArea } from '@ui-kit';
import {
  CompatibleWith,
  FilesTypes,
  EditKeywords,
} from '@features';

import ArrowIcon from '@ui-kit/icons/arrow-gray700.svg';
//import EyeOnIcon from '@ui-kit/icons/eye-gray400.svg';
import EyeOffIcon from '@ui-kit/icons/eye-off-gray400.svg';
import TrashIcon from '@ui-kit/icons/trash-gray400.svg';
import { useNavigate } from 'react-router-dom';
import productsService from '@entities/products/api';
import { 
  setAlertAC,
  Snackbar,
} from '@entities';
import { useDispatch } from 'react-redux';

const ProductEditInfo = ({
  product = {},
  onDeleteKeyword = () => {},
}) => {
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const PRODUCT_URL = process.env.REACT_APP_PRODUCTS_URL;
  const dispatch = useDispatch();

  const {
    id,
    //linkId,
    //pathToPreview,
    pathToBrowse,
    //title = '',
    //html_description = '',
    compatibility_draw = '',
    illustration_formats = '',
    key_words,
    //isPublic,
  } = product;

  const { formData, onChangeInput, onSubmitForm, setFormData } = useForm(
    product,
    handlerBeforeSubmitingForm
  );

  useEffect(() => {
    if (!product?.id) return;

    setFormData(product);

    return () => { setFormData({})}
    // eslint-disable-next-line
  }, [product]);

  async function handlerBeforeSubmitingForm() {
    const formErrors = productFormValid(formData);

    console.log('formErrors', formErrors);

    if (Object.keys(formErrors).length) {
      return setErrors(formErrors);
    }

    await productsService.updateProduct(id, formData);

    //dispatch(updateProductSettingsTC(formData));
    setErrors({});
  };

  const onBack = () => {
    console.log('onBack');
    setErrors({});
    navigate(-1);
  };

  const onPublic = async() => {
    console.log('onPublic');

    const isPublicUpdate = !product.isPublic;

    await productsService.updateProductSettings(id, { isPublic: isPublicUpdate });

    const status = isPublicUpdate ? 'published' : 'unpublished';

    const text = `Product was ${status} successfully !`;

    const snackbar = new Snackbar({
        status: 500,
        message: (
          <div className="">
            <h5 className="snack__header">
              {text}
            </h5>
          </div>
        ),
    });
    dispatch(setAlertAC(snackbar));

  };

  const onDelete = async() => {
    console.log('onDelete');

    await productsService.deleteProduct(id);

    const snackbar = new Snackbar({
        status: 500,
        message: (
          <div className="">
            <h5 className="snack__header">
              Product was deleted successfully !
            </h5>
          </div>
        ),
    });
    dispatch(setAlertAC(snackbar));

    navigate(-1);
  };

  /*const onDeleteKeyword = (keyword) => {
    onDeleteKeyword(keyword);
  };*/

  return (
      <div className="product-edit__container">
        <div className="product-edit__title-actions d-flex jc-sb">
          <Button
            theme="secondary-gray"
            size="lg"
            iconLeft={ArrowIcon}
            onClick={onBack}
          >Back</Button>
          <Button
            size="lg"
            onClick={onSubmitForm}
            isDisabled={!formData.isUnsaved}  
          >Save</Button>
        </div>
        <div className="product-edit__info-container">
          <div className="edit__info-title d-flex jc-sb">
            <Text as="h4">Product settings</Text>
            <div className="info-title__actions d-flex">
              <Button 
                theme="link-gray"
                size="lg"
                iconRight={EyeOffIcon}
                className="hide-product"
                onClick={onPublic}
              />
              <Button 
                theme="link-gray"
                size="lg"
                iconRight={TrashIcon}
                className="delete-product"
                onClick={onDelete}
              />
            </div>
          </div>
          <div className="edit__settings d-flex">
            <div className="edit__image-container">
            <Icon 
              src={`${PRODUCT_URL}/${pathToBrowse}`}
              alt={`info-img-${id}`}
              className="mb-24"
            />
            <EditKeywords
                onDeleteKeyword={onDeleteKeyword}
                keywords={key_words}
                length={20}
              />
            </div>
            <form className="edit__form">
              <Input 
                name="title"
                type="text"
                label="Name"
                className="edit__name mt-22"
                value={formData?.title || ''}
                onChange={onChangeInput}
                maxLengthCustom={50}
                //className={errors.Name && 'form-item__error'}
              />
              {errors.title && (
                <Text as="span" className="error">
                  {errors.title}
                </Text>
              )}
              <TextArea 
                name="html_description"
                label="Description"
                className="edit__description mt-24 mb-24"
                value={formData?.html_description || ''}
                onChange={onChangeInput}
                maxLengthCustom={200}
              />
              {errors.html_description && (
                <Text as="span" className="error">
                  {errors.html_description}
                </Text>
              )}
              <CompatibleWith compatibility_draw={compatibility_draw} />
              <FilesTypes illustrationFormats={illustration_formats} />
            </form>
          </div>
        </div>
      </div>
  );
};

export default ProductEditInfo;
