import { Validation } from '@shared/lib/utils';

const productFormValid = (formData) => {
  let errorsState = {};

  const validationTitle = new Validation({
    field: formData.title,
    title: 'New title',
  })
    .isRequired()
    .minLenght(8)
    .maxLenght(255)
    // .containLettersAndNumbers()
    .getErrors();

  if (validationTitle.length) {
    errorsState = {
      ...errorsState,
      title: validationTitle[0],
    };
  }

  return errorsState;
};

export default productFormValid;
