import { ApiService } from '@shared/api';

class ProductsService extends ApiService {
  baseUrl;
  // eslint-disable-next-line
  constructor(props) {
    super(props);

    this.baseUrl = '/products'
  }

  async getProducts(params) {
    return await this.instance.get(`${this.baseUrl}/public?${this.generateQueryString(params)}`);
  }

  async getSoftProducts(params) {
    return await this.instance.get(`${this.baseUrl}/public/soft?${this.generateQueryString(params)}`);
  }

  async getSimilarProducts(productId, params) {
    return await this.instance.get(`${this.baseUrl}/public/related/${productId}?${this.generateQueryString(params)}`);
  }

  async getAmount(from) {
    if (!Object.keys(from).length) {
      return await this.instance.get(`${this.baseUrl}/amount`);
    }
    
    return await this.instance.get(`${this.baseUrl}/amount?${this.generateQueryString(from)}`);
  }

  async getArchive(param) {
    return await this.instance.get(
      `${this.baseUrl}/archive?${this.generateQueryString(param)}`
    );
  };

  async getReadyProducts(params) {
    return await this.instance.get(`${this.baseUrl}?${this.generateQueryString(params)}`);
  }

  async updateProduct(id, formData) {

    this.setFormDataInterceptors();

    return await this.instance.put(`${this.baseUrl}/${id}`, this.getFormData(formData));
  };

  async updateProductSettings(id, formData) {

    this.setFormDataInterceptors();

    return await this.instance.patch(`${this.baseUrl}/${id}`, this.getFormData(formData));
  };

  async updateProductsSettings(params) {
    return await this.instance.patch(`${this.baseUrl}/batch`, params);
  };

  async deleteProduct(id) {
    return await this.instance.delete(`${this.baseUrl}/${id}`);
  };

  async deleteProducts(ids) {
    return await this.instance.delete(`${this.baseUrl}/batch`, { data: ids });
  };

  async getPromotedProducts(params) {
    return await this.instance.get(`${this.baseUrl}?${this.generateQueryString(params)}`);
  }

}

const productsService = new ProductsService();

export default productsService;
